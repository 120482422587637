import { patchState, signalStoreFeature, type, withMethods, withState } from '@ngrx/signals';
import { NamedListCompleteSlice } from '@aston/foundation';
import { TranslateService } from '@ngx-translate/core';
import { Signal, inject } from '@angular/core';
import { Router } from '@angular/router';
import debounce from 'lodash/debounce';
import { of } from 'rxjs';

import { currentTenantId } from '../../authentication-module/functions';
import { INotificationItem } from '../../shared-module/models';
import * as RoutesDefinitions from '../../routes-definitions';
import { TenantUrl } from '../../shared-module/functions';
import { AppConstants } from '../../app.constants';

const NOTIFICATIONS = 'notifications';

export const withDesktopNotifications = () => signalStoreFeature(
	{
		state: type<NamedListCompleteSlice<INotificationItem, any, typeof NOTIFICATIONS>>(),
		signals: type<{ unreadCount: Signal<number> }>(),
	},

	withState({
		lastNotificationOnDesktop: 0,
	}),

	withMethods(store => {
		const i18n = inject(TranslateService)
		const router = inject(Router)

		return {
			show: (item: INotificationItem, unreadCount: number | undefined = undefined) => {
				// dont show the same notif twice
				if (store.lastNotificationOnDesktop() >= item.id) return;
				patchState(store, { lastNotificationOnDesktop: item.id })

				const tName = i18n.instant('App.Name')
				const isSingle = unreadCount === undefined
				const tBaseline = i18n.instant('App.Notifs.Baseline')
				const tOne = i18n.instant('TopBar.Notifications.Desktop.One')
				const tSummary = i18n.instant('TopBar.Notifications.Desktop.Summary')
		
				const tTitle = isSingle
					? i18n.stream('TopBar.Notifications.Categories.' + item.category)
					: of(unreadCount)
		
				const route = RoutesDefinitions.getNotificationsDashboardFullPath()
				const url = new TenantUrl(route)

				const notification = new Notification(`${tName} - ${tBaseline}`, <any>{
					body: isSingle ? tOne : tSummary + ` (${tTitle})`,
					icon: '/assets/images/logos/logo_desktop_acc.png',
					data: [url.tenantId, route, url.toString()],
				});

				// Do our best to open this notification, testing the origin tenant
				// Bug 29216: Conviction-lack sur une notification
				notification.addEventListener('click', event => {
					const [ tenantId, route, url ] = (event.target as any).data
					if (tenantId === +currentTenantId()) {
						router.navigateByUrl(route)
					} else {
						window.location.assign(url)
					}
				})
			}
		}
	}),

	withMethods(store => {
		return {
			// on LoadNotificationsSuccess
			syncDesktopNotifications: debounce(() => {
				if (! ('Notification' in window)) {
					console.debug(`[notifictions] desktop notification API not available`)
					return;
				}

				const unreadCount = store.unreadCount();
				const list = store.notificationsList();

				const show = () => {
					if (unreadCount === 1) {
						store.show(list.items.find(n => !n.isRead))
	
					} else if (unreadCount > 1) {
						store.show(list.items.reduce((acc, n) => acc.id < n.id ? n : acc), unreadCount)
					}
				}

				// request permission
				if (Notification.permission === 'granted') {
					show()

				} else if (Notification.permission !== 'denied') {
					// we need to ask the user for permission
					Notification.requestPermission().then(permission => {
						// if the user accepts, let's create a notification
						if (permission === 'granted') show()
					})
				}

			}, AppConstants.NOTIFICATIONS_DESKTOP_TIMEOUT, { trailing: true }),
		}
	}),
)
