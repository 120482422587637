/* eslint-disable @typescript-eslint/no-unused-vars */
import { AchievementIconPipe, BadgeAchievementEventData, BadgeLevelType, BadgeType, IAchievementBadge } from '@aston/user-badge';
import { signalStoreFeature, withHooks, withMethods } from '@ngrx/signals';
import { NotificationService, notNone } from '@aston/foundation';
import { TranslateService } from '@ngx-translate/core';
import { catchError, tap } from 'rxjs/operators';
import { inject } from '@angular/core';
import { EMPTY } from 'rxjs';

import { WebSocketService } from '../../shared-module/services/websocket.service';
import { WsEventType } from '../../shared-module/enums/ws-event-type.enum';
import { environment } from '../../../environments/environment';

export const withAchievementsNotifications = () => signalStoreFeature(
	withMethods(_store => {
		const toasts = inject(NotificationService)
		const pipe = inject(AchievementIconPipe)
		const i18n = inject(TranslateService)

		return {
			showAchievementToast(event: BadgeAchievementEventData) {
				const level = event.badgeLevel
				const type = event.badgeType
				const name = i18n.instant(`AchievementBadge.${type}.Name`)
				const text = [
					`${i18n.instant('AchievementBadge.YouUnlocked', { name })}`,
					level === BadgeLevelType.None ? '' : `(${i18n.instant(`AchievementBadge.Levels.${level}`)})`,
					`<i style="font-size: 2em !important" class="ml-2 fa ${pipe.transform({ type, level } as IAchievementBadge)}"></i>`
				].join(' ')
				const block = `<div class="d-flex align-items-center">${text}</div>`
				toasts.success({ message: '_', messageParams: { text: block } })
			}
		}
	}),

	withHooks({
		onInit(store, ws = inject(WebSocketService)) {
			ws.ofType(WsEventType.BadgeAchievement)
				.pipe(tap(event => store.showAchievementToast(JSON.parse(event))), catchError(() => EMPTY))
				.subscribe()

			// if (environment.dev) {
			// 	const badgeLevelTypes = Object.values(BadgeLevelType).filter(notNone)
			// 	Object.values(BadgeType).filter(notNone).forEach((badgeType, i) => setTimeout(
			// 		() => store.showAchievementToast({
			// 			badgeLevel: badgeLevelTypes[Math.floor(Math.random() * badgeLevelTypes.length)],
			// 			badgeType,
			// 		}), 5000 * i
			// 	))
			// }
		},
	})
)
