import { callStated, callStatedList, exportStated, withCallState, withExportCallState, withListState } from '@aston/foundation';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { auditTime, filter, firstValueFrom, map } from 'rxjs';
import { USER_FILTERS_CONNECTOR } from '@aston/filters';
import { computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { NotificationsService } from '../../shared-module/services/notifications.service';
import { INotificationItem, INotificationSetting } from '../../shared-module/models';
import { WebSocketService } from '../../shared-module/services/websocket.service';
import { NotificationCategory, WsEventType } from '../../shared-module/enums';
import { NotificationListSortProp, NotificationsListMode } from '../enums';
import * as AppStoreSelectors from '../../root-store/app-store/selectors';
import { AppConstants } from '../../app.constants';

import { withAchievementsNotifications } from './notifications.achievements';
import { withDesktopNotifications } from './notifications.desktop';
import { withNotificationsRouter } from './notifications.router';

const NOTIFICATIONS = 'notifications';
const SETTINGS = 'settings';

export const UserNotificationsStore = signalStore(
	{ providedIn: 'root' },

	withState({
		isPanelOpen: false,
		showUnreadOnly: false,
		settings: [] as INotificationSetting[],
	}),

	withCallState({
		collection: SETTINGS,
	}),

	withListState<INotificationItem, NotificationListSortProp, typeof NOTIFICATIONS>({
		defaultPageSize: AppConstants.LIST_PAGE_SIZE_IGNORED,
		prefsManagerToken: USER_FILTERS_CONNECTOR,
		domain: `dunningActions.list`,
		collection: NOTIFICATIONS,
	}),

	withExportCallState({ collection: NOTIFICATIONS }),

	withComputed(store => ({
		notificationsForSidebar: computed(() => ({
			items: store.notificationsList().items.filter(n => store.showUnreadOnly() ? !n.isRead : true)
		})),
		unreadCount: computed(() => store.notificationsList().items.filter(n => !n.isRead).length),
		hasUnread: computed(() => store.notificationsList().items.some(n => !n.isRead)),
		allCount: computed(() => store.notificationsList().items.length),
	})),

	withDesktopNotifications(),
	withAchievementsNotifications(),

	withMethods(store => {
		const notifications = inject(NotificationsService)
		const ws = inject(WebSocketService)
		const fatStore = inject(Store)
		const router = inject(Router)

		const reload = () => callStatedList(notifications.getNotifications(), store, NOTIFICATIONS)
			.then(_ => store.syncDesktopNotifications())

		const markAs = (read: boolean, ...ids: number[]) => Promise.resolve()
			.then(_ => callStated(notifications.markReadNotifications(ids, read), store, NOTIFICATIONS, { stateOnly: true }))
			.then(_ => reload())

		const loadNotificationSettings = () => callStated(notifications.getNotificationsSettings(), store, SETTINGS)

		return {
			togglePanel: () => patchState(store, { isPanelOpen: !store.isPanelOpen() }),
			closePanel: () => patchState(store, { isPanelOpen: false }),

			changeMode: (mode: NotificationsListMode) => patchState(store, { showUnreadOnly: mode !== NotificationsListMode.All }),
		
			loadNotificationList: () => reload(),
			exportNotifications: (mode: NotificationsListMode, categories: NotificationCategory[]) => 
				exportStated(notifications.exportNotificationsList(mode, categories), store, NOTIFICATIONS),
			loadNotificationSettings,
			updateNotificationSettings: (setting: INotificationSetting) => callStated(notifications.updateNotificationSetting(setting), store, SETTINGS, { stateOnly: true })
				.then(loadNotificationSettings),
			
			getOne: (id: number) => notifications.getNotification(id),

			select: async (id: number, opts: { route: string, extras: any }) => {
				await firstValueFrom(notifications.markReadNotifications([id], true))
				if (opts?.route !== undefined) router.navigate([opts?.route], opts?.extras)
				reload()
			},

			listenToWS: () => ws.ofType(WsEventType.RefreshUserNotifications).pipe(auditTime(500),map(_ => reload())),
			loadOnLogin: () => fatStore.select(AppStoreSelectors.selectCurrentUser).pipe(filter(Boolean), auditTime(500), map(_ => [ loadNotificationSettings(), reload() ])),

			markAsRead: (ids: number[]) => markAs(true, ...ids),
			markAsUnRead: (ids: number[]) => markAs(false, ...ids),

			delete: (ids: number[]) => Promise.resolve()
				.then(_ => callStated(notifications.deleteNotifications(ids), store, NOTIFICATIONS, { stateOnly: true }))
				.then(_ => reload())
		}
	}),

	withNotificationsRouter(),

	withHooks({
		onInit(store) {
			store.loadOnLogin().subscribe()
			store.listenToWS().subscribe()
		}
	})
)

export type UserNotificationsStore = InstanceType<typeof UserNotificationsStore>;
